import { useQuery, UseQueryOptions } from "react-query";
import { useAggregatedQueries } from "../../hooks/useAggregatedQueries";
import { api } from "../api";
import { PulseMeter } from "../types/PulseMeter";

enum QueryKeys {
  PulseMeter = "pulse-meter",
}

export const useGetPulseMeter = (
  deviceId: string | undefined,
  options?: UseQueryOptions<PulseMeter, Error>
) => {
  const { data, ...rest } = useQuery<PulseMeter, Error>(
    [QueryKeys.PulseMeter, deviceId],
    () => api.get(`/v2/pulsemeter/${deviceId}`),
    {
      staleTime: Infinity,
      ...options,
      enabled: Boolean(deviceId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    pulseMeter: data,
    ...rest,
  };
};

export const useGetPulseMeters = (deviceIds: string[], options?: UseQueryOptions<PulseMeter, Error>) => {
  const { data, ...rest } = useAggregatedQueries<PulseMeter, Error>(
    deviceIds.map((deviceId) => ({
      queryKey: [QueryKeys.PulseMeter, deviceId],
      queryFn: () => api.get(`/v2/pulsemeter/${deviceId}`),
      ...options,
      enabled: Boolean(deviceId) && options?.enabled !== false,
    }))
  );

  return {
    pulseMeters: data,
    ...rest,
  };
};
