import React, { useState } from "react";
import { Box, Flex, Divider, HStack, Text } from "@chakra-ui/layout";
import { DeviceReadingItem } from "../area-device-reading-item/DeviceReadingItem";
import Co2AreasIcon from "../areas-icons/Co2AreasIcon";
import HumidityAreaIcon from "../areas-icons/HumidityAreaIcon";
import SignalStrengthIndicator from "../../indicators/SignalStrengthIndicator";
import { DeviceWithLastSample } from "../../../lib/api/types/DeviceWithLastSample";
import { Image, useDisclosure } from "@chakra-ui/react";
import { RoomLocationModal } from "./RoomLocationModal";
import { makeSampleBreakdown } from "./makeSampleBreakdown";
import { BatteryLevel } from "./BatteryLevel";
import TempAreasIcon from "../areas-icons/TempAreasIcon";
import { AREA_CARD_HORIZONTAL_PADDING } from "../inside/InsideDetailsPanel";
import { OutsideWithReading } from "../../../lib/api/types/OutsideWithReading";
import { ManageRoomLocationMenu } from "../../property/room/ManageRoomLocationMenu";
import { LocationType } from "../../../lib/api/properties/hooks";
import { RoomLocationWithReading } from "../../../lib/api/types/RoomLocation";
import AirwitzPMIcon from "../areas-icons/AirwitzPMIcon";
import { getDeviceIcon } from "./getDeviceIcon";
import { formatDeviceName } from "../../../lib/utils/formatDeviceName";

interface RoomLocationReadingProps {
  device?: DeviceWithLastSample;
  roomLocation: RoomLocationWithReading | OutsideWithReading;
  showCovidRiskLevel?: boolean;
  locationType: "ROOMLOCATION" | "OUTSIDE";
  propertyId: string;
  showRoomName?: boolean;
  disableInput?: boolean;
}

export const RoomLocationReading = ({
  roomLocation,
  device,
  showCovidRiskLevel = false,
  locationType,
  propertyId,
  showRoomName = true,
  disableInput = false,
}: RoomLocationReadingProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editMode, setEditMode] = useState(false);

  const breakdown = device ? makeSampleBreakdown(device, showCovidRiskLevel) : undefined;

  if ((roomLocation as RoomLocationWithReading).parentRoomLocationId) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <RoomLocationModal
          breakdown={breakdown}
          roomLocation={roomLocation}
          onClose={() => {
            setEditMode(false);
            onClose();
          }}
          isOpen={isOpen}
          locationType={locationType}
          propertyId={propertyId}
          editMode={editMode}
          hasDevice={Boolean(device)}
        />
      )}
      <Flex
        py="4px"
        px={AREA_CARD_HORIZONTAL_PADDING}
        dir="row"
        _hover={{ bg: disableInput ? "transparent" : "gray.50" }}
        cursor={disableInput ? "default" : "pointer"}
        onClick={(e) => {
          if (!disableInput) {
            e.stopPropagation();
            onOpen();
          }
        }}
      >
        <HStack flex={1}>
          <Box ml="-4px" w={42}>
            {getDeviceIcon(device?.type)}
          </Box>

          <Box>
            {Boolean(showRoomName && roomLocation.name) && <Text fontWeight={500}>{roomLocation.name}</Text>}
            {Boolean(device) && <Text>{formatDeviceName(device!.type)}</Text>}
            {breakdown?.healthyBreakdownText && (
              <Text noOfLines={1} color="gray.500">
                {breakdown?.healthyBreakdownText}
              </Text>
            )}
          </Box>
        </HStack>
        <Box pr="8px" pl={"16px"} display="flex" flexDirection="row" alignItems="center">
          {Boolean(roomLocation.image) && (
            <Image
              alt={`${roomLocation.name} Image`}
              onClick={() => window.open(roomLocation.image, "_blank")}
              cursor="pointer"
              src={roomLocation.image}
              borderRadius="4px"
              height="32px"
              width="32px"
              mr="16px"
              onError={(e) => {
                e.currentTarget.style.display = "none";
              }}
            />
          )}
          {Boolean(breakdown && !breakdown.isOnline) && (
            <Box flex={1} justifyContent="flex-end">
              <Text color={"red"}>Offline</Text>
            </Box>
          )}

          {Boolean(!device) && (
            <Box flex={1} justifyContent="flex-end">
              <Text>No Device Assigned</Text>
            </Box>
          )}

          {Boolean(breakdown && breakdown.isOnline) && (
            <>
              {Boolean(breakdown?.co2) && (
                <DeviceReadingItem
                  isHighRisk={!breakdown!.co2!.isHealthy}
                  value={breakdown!.co2!.label}
                  icon={
                    <Co2AreasIcon
                      width={17}
                      height={17}
                      color={breakdown?.co2?.isHealthy ? undefined : "#FB5169"}
                    />
                  }
                />
              )}
              {breakdown?.covidCare && (
                <Box mr="16px">
                  <Text fontWeight={600} fontSize={12} color={breakdown.covidCare.riskLevelColor}>
                    {breakdown?.covidCare?.riskLevelLabel}
                  </Text>
                </Box>
              )}

              {Boolean(breakdown?.temp) && (
                <DeviceReadingItem
                  isHighRisk={!breakdown?.temp?.isHealthy}
                  value={breakdown!.temp!.label!}
                  icon={
                    <TempAreasIcon
                      width={18}
                      height={18}
                      color={breakdown?.temp?.isHealthy ? undefined : "#FB5169"}
                    />
                  }
                />
              )}
              {Boolean(breakdown?.humidity) && (
                <DeviceReadingItem
                  isHighRisk={!breakdown?.humidity?.isHealthy}
                  value={breakdown!.humidity!.label}
                  icon={
                    <HumidityAreaIcon
                      width={16}
                      height={16}
                      color={breakdown?.humidity?.isHealthy ? undefined : "#FB5169"}
                    />
                  }
                />
              )}
              {Boolean(breakdown?.pm1) && (
                <DeviceReadingItem
                  isHighRisk={undefined}
                  value={breakdown!.pm1!.label}
                  icon={<AirwitzPMIcon size={16} />}
                />
              )}
              {Boolean(breakdown?.pm2_5) && (
                <DeviceReadingItem
                  isHighRisk={undefined}
                  value={breakdown!.pm2_5!.label}
                  icon={<AirwitzPMIcon size={16} />}
                />
              )}
              {Boolean(breakdown?.pm10) && (
                <DeviceReadingItem
                  isHighRisk={undefined}
                  value={breakdown!.pm10!.label}
                  icon={<AirwitzPMIcon size={16} />}
                />
              )}

              {Boolean(breakdown?.occupied) && (
                <DeviceReadingItem
                  isHighRisk={breakdown!.occupied?.value === 1}
                  value={breakdown!.occupied!.label}
                  icon={<AirwitzPMIcon size={16} />}
                />
              )}

              {breakdown?.battery !== undefined && (
                <>
                  <Box h={"16px"}>
                    <Divider mr={2} bg="gray.50" orientation="vertical" />
                  </Box>
                  <Box pl={2} pr={2} borderRadius={10} mr="4px" display="flex" alignItems="center">
                    <BatteryLevel battery={breakdown.battery} />
                  </Box>
                </>
              )}

              {Boolean(typeof breakdown?.signalStrengthPercentage !== "undefined") && (
                <SignalStrengthIndicator signalStrength={breakdown!.signalStrengthPercentage!} />
              )}
            </>
          )}
        </Box>
        {!disableInput && (
          <ManageRoomLocationMenu
            propertyId={propertyId}
            locationId={roomLocation.id}
            locationName={roomLocation.name}
            locationType={locationType === "OUTSIDE" ? LocationType.Outside : LocationType.RoomLocation}
            onEditClick={() => {
              setEditMode(true);
              setTimeout(onOpen);
            }}
            hasDevice={!!roomLocation.device}
          />
        )}
      </Flex>
    </>
  );
};

// <BatteryIcon batteryLevel={2} />
// <Text ml={2}>20%</Text>
// <Box display="flex" flexDirection="row" alignItems="baseline" ml={2}>
//   <NetworkConnectionIcon connection={3} />
// </Box>
