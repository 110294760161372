import { DeviceType } from "../lib/api/types/GenericDevice";

export const deviceTypeIsEnvironmental = (deviceType: DeviceType | string | undefined) => {
  if (!deviceType) {
    return false;
  }

  switch (deviceType.toUpperCase()) {
    case DeviceType.ENVIROQ:
    case DeviceType.AIRWITS:
    case DeviceType.AIRWITS_CO2:
    case DeviceType.AIRWITS_CO2_PLUS:
    case DeviceType.AIRWITS_IAQ:
    case DeviceType.AIRWITS_PM:
    case DeviceType.THERMALQ:
      return true;
    default:
      return false;
  }
};

export const deviceTypeIsOccupancy = (deviceType: DeviceType | string | undefined) => {
  if (!deviceType) {
    return false;
  }

  switch (deviceType.toUpperCase()) {
    case DeviceType.DETECTIFY:
    case DeviceType.ROOM_RADAR:
      return true;
    default:
      return false;
  }
};
