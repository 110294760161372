import { Box, Flex, Image, Tooltip } from "@chakra-ui/react";
import pluralize from "pluralize";
import { PIDeviceTypes } from "../../lib/api/types/PropertyInfo";
import { imageForDeviceType } from "../areas/room-location-reading/getDeviceIcon";

const makePlural = (label: string, count: number) => {
  if (label === "Airwits" && count === 1) return "Airwits"; // fix for singularising airwits

  return pluralize(label, count).replace("+s", "+").replace("PMS", "PMs"); // custom fixes for bad plurals
};

const DeviceCount = ({
  count,
  deviceType,
  label,
  height = 20,
  width = 20,
}: {
  count: number;
  deviceType: PIDeviceTypes;
  label: string;
  height?: number;
  width?: number;
}) => {
  const numCount = count ? count : 0;

  const labelPlural = makePlural(label, numCount);

  if (numCount === 0) {
    return null;
  }

  return (
    <Tooltip label={`${count} ${labelPlural}`}>
      <Flex mr={3} alignItems="center">
        <Flex mr={"8px"} alignItems="center">
          <Image
            src={"/assets/devices/" + imageForDeviceType(deviceType)}
            alt={label}
            height={height}
            width={width}
          />
        </Flex>
        <Box>{numCount}</Box>
      </Flex>
    </Tooltip>
  );
};

export const DeviceCounts = ({
  deviceCounts,
  size = "md",
}: {
  deviceCounts: Record<PIDeviceTypes, number>;
  size?: "sm" | "md";
}) => {
  return (
    <Flex textAlign={"center"} alignItems="center">
      <DeviceCount
        label="EnviroQ"
        deviceType={"enviroq"}
        count={deviceCounts.enviroq}
        height={{ sm: 6, md: 10 }[size]}
        width={{ sm: 6, md: 10 }[size]}
      />
      <DeviceCount
        label="ThermalQ"
        deviceType={"thermalq"}
        count={deviceCounts.thermalq}
        height={{ sm: 6, md: 10 }[size]}
        width={{ sm: 6, md: 10 }[size]}
      />
      <DeviceCount
        label="HotDrop"
        deviceType={"hotdrop"}
        count={deviceCounts.hotdrop}
        height={{ sm: 7, md: 14 }[size]}
        width={{ sm: 7, md: 14 }[size]}
      />
      <DeviceCount
        label="VoltDrop"
        deviceType={"voltdrop"}
        count={deviceCounts.voltdrop}
        height={{ sm: 7, md: 14 }[size]}
        width={{ sm: 7, md: 14 }[size]}
      />
      <DeviceCount
        label="Airwits"
        deviceType={"airwits"}
        count={deviceCounts.airwits}
        height={{ sm: 5, md: 10 }[size]}
        width={{ sm: 5, md: 10 }[size]}
      />
      <DeviceCount
        label="Airwits IAQ"
        deviceType={"airwits_iaq"}
        count={deviceCounts.airwits_iaq}
        height={{ sm: 5, md: 10 }[size]}
        width={{ sm: 5, md: 10 }[size]}
      />
      <DeviceCount
        label="Airwits CO2"
        deviceType={"airwits_co2"}
        count={deviceCounts.airwits_co2}
        height={{ sm: 5, md: 10 }[size]}
        width={{ sm: 5, md: 10 }[size]}
      />
      <DeviceCount
        label="Airwits CO2 +"
        deviceType={"airwits_co2_plus"}
        count={deviceCounts.airwits_co2_plus}
        height={{ sm: 5, md: 10 }[size]}
        width={{ sm: 5, md: 10 }[size]}
      />
      <DeviceCount
        label="Airwits PM"
        deviceType={"airwits_pm"}
        count={deviceCounts.airwits_pm}
        height={{ sm: 7, md: 14 }[size]}
        width={{ sm: 7, md: 14 }[size]}
      />
      <DeviceCount
        label="Detectify"
        deviceType={"detectify"}
        count={deviceCounts.detectify}
        height={{ sm: 5, md: 12 }[size]}
        width={{ sm: 5, md: 12 }[size]}
      />
      <DeviceCount
        label="FlexSense"
        deviceType={"flexsense"}
        count={deviceCounts.flexsense}
        height={{ sm: 7, md: 12 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Gateway"
        deviceType={"gateway"}
        count={deviceCounts.gateway}
        height={{ sm: 7, md: 12 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Room Radar"
        deviceType={"room_radar"}
        count={deviceCounts.room_radar}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Pulse Meter (Water)"
        deviceType={"adeunis_pulse_water"}
        count={deviceCounts.adeunis_pulse_water}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Pulse Meter"
        deviceType={"adeunis_pulse"}
        count={deviceCounts.adeunis_pulse}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="EMS2 Desk"
        deviceType={"ems2desk"}
        count={deviceCounts.ems2desk}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="ERS2 Eye"
        deviceType={"ers2eye"}
        count={deviceCounts.ers2eye}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Milesight EM300"
        deviceType={"milesight_em300"}
        count={deviceCounts.milesight_em300}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Milesight AM103L"
        deviceType={"milesight_am103"}
        count={deviceCounts.milesight_am103}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
      <DeviceCount
        label="Milesight WS202"
        deviceType={"milesight_ws202"}
        count={deviceCounts.milesight_ws202}
        height={{ sm: 4, md: 6 }[size]}
        width={{ sm: 7, md: 12 }[size]}
      />
    </Flex>
  );
};
