export function capitalize(str: string) {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function snakeCaseToStartCase(str: string) {
  if (!str) {
    return "";
  }

  const parts = str.toLowerCase().split("_");
  return parts.map((p) => capitalize(p)).join(" ");
}

export function kebabCaseToStartCase(str: string) {
  if (!str) {
    return "";
  }

  const parts = str.toLowerCase().split("-");
  return parts.map((p) => capitalize(p)).join(" ");
}

export function diffInDays(date1: string | Date, date2: string | Date) {
  // Get timestamps of both dates
  const timestamp1 = typeof date1 === "string" ? Date.parse(date1) : date1.valueOf();
  const timestamp2 = typeof date2 === "string" ? Date.parse(date2) : date2.valueOf();

  // One day in milliseconds
  const oneDay = 24 * 60 * 60 * 1000;

  // Calculate the difference in milliseconds
  const diffMilliseconds = Math.abs(timestamp2 - timestamp1);

  // Convert to days and return
  return Math.round(diffMilliseconds / oneDay);
}

export function replaceLast(str: string, find: string, replace: string) {
  const index = str.lastIndexOf(find);

  if (index >= 0) {
    return str.substring(0, index) + replace + str.substring(index + find.length);
  } else {
    return str;
  }
}
